











































































import Vue from 'vue';
import MigrationForm from './components/MigrationForm.vue'
import NewUserForm from './components/NewUserForm.vue'

export default Vue.extend({
  name: 'App',

  components: {
    MigrationForm, NewUserForm
  },

  data: () => ({
    selected: null,
    qs: [
      {
        title: "Who is providing this service?",
        body: "Beekeeper Studio, Inc, we're the team behind Beekeeper Studio. We've been working in data analytics for a long time and know how to run mission-critical software.",
        link: {
          text: "Learn more about us",
          url: "https://www.beekeeperstudio.io/about"
        }
      },
      {
        title: "How do I migrate?",
        body: "Before Hosted Redash shuts down you will receive an email containing an invitation to our migration tool. By following the prompts on screen you should be able to migrate in less than 30 minutes."
      },
      {
        title: "How long will migration take?",
        body: "Data migration is fully automated and will only take 30-60 minutes. We'll email you when it completes, and if anything doesn't work correctly we'll work with you personally to fix it."
      },
      {
        title: "How much work will it be to migrate?",
        body: "The migration is fully automated. For security purposes, you will need re-enter your data source passwords after the migration completes."
      },
      {
        title: "What If I Need Help With Migrating?",
        body: "If you sign up for an annual plan we'll do the whole migration for you, free of charge."
      },
      {
        title: "What Else Needs Changing After Migration Is Complete?",
        body: "For security reasons you will need to re-enter your datasource passwords after migration, and any links you have shared with third parties, or embedded in documents will need changing from app.redash.io/* to dash.beekeeperstudio.io/*"
      },

    ]
  }),
});
