
























import Vue from 'vue';
import axios from 'axios';
import qs from 'qs'
export default Vue.extend({
  data() {
    return {
      submitting: false,
      error: null,
      submitted: false,
      result: {
        name: (null as string | null),
        email: (null as string | null),
        'form-name': 'submission',
        submissionType: 'newUser'
      }
    }
  },
  methods: {
    async submit() {
      this.submitting = true
      this.error = null
      try {
        if (!this.result?.email?.length || !this.result?.name?.length) {
          throw new Error("Email and name are both required")
        }
        const response = await axios({
          method: 'POST',
          url: '/',
          data: qs.stringify(this.result),
          headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8'}          
        })

        if (response.status !== 200) throw new Error("Error submitting form")
        this.submitted = true
      } catch (ex) {
        this.error = ex
      } finally {
        this.submitting = false
      }
    }
  }
})
