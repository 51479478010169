







































/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'

function notBlank(s: string | null | undefined) {
  return s && s.length
}

export default Vue.extend({
  data() {
    return {
      formName: 'migration',
      plans: [
        {
          text: "Starter",
          value: 'starter'
        },
        {
          text: "Pro",
          value: "pro"
        },
        {
          text: "Business",
          value: 'business'
        },
        {
          text: "I'm not sure",
          value: 'notsure'
        }
      ],
      result: {
        dataSources: 1,
        name: null,
        email: null,
        comments: null,
        'form-name': 'submission',
	submissionType: "migration"
      },
      submitting: false,
      submitted: false,
      error: null,
      rules: {
          required: (value: any) => !!value || 'Required.',
          counter: (value: any) => value.length <= 20 || 'Max 20 characters',
          email: (value: any) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
    }
  },
  computed: {
    submitDisabled() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-error ignore this
      const gotAll = notBlank(this.result.name) && notBlank(this.result.email)
      return !gotAll
    }
  },
  methods: {
    clearResult() {
      this.result = {
        dataSources: 1,
        email: null, name: null, comments: null, 'form-name': 'submission', submissionType: "migration"
      }
    },
    async submit() {
      this.submitting = true
      this.error = null
      try {
        const response = await axios({
        method: 'post',
        url: '/',
        data: qs.stringify(this.result),
        headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8'}
	});
        if (response.status !== 200) throw new Error(`Error submitting form: ${response.status}`)
        this.submitted = true
      } catch (ex) {
        this.error = ex
      } finally {
        this.submitting = false
      }
    }
  }
})
